import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import {FaPhoneAlt} from "react-icons/fa"

const Button = ({size, label, use}) => {
    switch (use) {
        case "form":
            return(
                <button className="btn-form-action">{label}</button>
            )

        case "cta":
            return(
                <button className="btn-cta"><FaPhoneAlt size="2rem" style={{margin: "0 2rem 0 0"}}></FaPhoneAlt>{label}</button>
            )

        default:
            return(
                <button className="btn-default-action">{label}</button>
            )
    }
    
}

export default Button