// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from "./basic-components/button";


const Header = ({ pageData, headerTitle, description, backgroundUrl, primaryCTALabel, primaryCTAUrl, secondaryCTALabel, secondaryCTAUrl }) => {

  var headerStyle;
  if(pageData.headerBgImage){
    headerStyle = {
      backgroundImage: "url(" + pageData.headerBgImage + ")"
    }
  }

      if(pageData){
        return(
          <header className="header-bg" style={headerStyle}>
  
            <div className="header-container uk-animation-slide-bottom-medium uk-position-bottom">
              <div className="uk-panel">
                
                
                <h1>{pageData.headerTitle}</h1>
                {/* <p>{pageData.headerDescription}</p> */}
                <div className="header-cta-container">
                  <a href={pageData.headerCTAPrimaryUrl}>
                    <Button label={pageData.headerCTAPrimaryLabel} use=""></Button>

                  </a>
                  {/* <a href={pageData.headerCTASecondaryUrl} className="btn-secondary">{pageData.headerCTASecondaryLabel}</a> */}
                </div>
                
              </div>
  
            </div>
          </header>
        )
      }else{return("")}
      

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
