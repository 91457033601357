import React from "react"
import {Link} from "gatsby";
import Logo from "../assets/img/SEODevelopments_fullLogo.png"

// const sendSignup = event =>{

//     var cform = {
//       email: document.getElementById("signup-email").value
//     }
//     console.log(cform);
//     var submitBtn = document.getElementById("sign-up-submit");
//     submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
//     submitBtn.classList.add("form-submitting");
  
//     var body = {
//       Token: 'phHRMocDrnRvQgKl0zcjMOhD8ZeaWrnn2local',
//       FormData: JSON.stringify(cform)   
//     };
//     fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
//       method: "POST",
//       body: JSON.stringify(body),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }).then(response => 
//       response.text().then(function (responseText) {
//         console.log("response text: " + responseText);
//         if(responseText.localeCompare('"Form sent successfully."') === 0){
//           //success send message
//           console.log("success"+ responseText);
//           submitBtn.innerHTML = "Sent!";
//           submitBtn.classList.remove("form-submitting");
//         }else{
//           //error sending message
//           console.log("something went wrong");
//           submitBtn.innerHTML = "Ups... Try again!";
//           submitBtn.classList.remove("form-submitting");
//         }
          
  
//       })
//     )
//     // console.log("Message sent");
  
//     event.preventDefault();
//   }

const Footer = () => (
    <div>

        <section className="footer-section">
          <div className="footer-wrapper">
              <div data-uk-grid>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title"></div>
                    <Link to="/"><img src={Logo} alt="SEO Developments logo" width="200"/></Link>
                      
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="uk-hidden">
                      
                    </div>
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="uk-hidden">
                      
                    </div>
                  </div>
                  <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <div className="col-title"></div>
                    <ul>
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      <li><Link to="/cookie-policy">Cookie Policy</Link></li>
                      <li><Link to="/terms-conditions">Terms &amp; Conditions</Link></li>
                      <li><Link to="/complaints-procedure">Complaints Procedure</Link></li>
                    </ul>
                  </div>
                  
              </div>
              <div className="footer-details">Administered by SEO Developments, registered in England, at 42 Folkestone Road, Southport, England, PR8 5PH. Company Number: 12902071. Authorised and regulated by the Financial Conduct Authority. Firm number: 938168.</div>
          </div>
        </section>

        <footer>
          <div className="uk-child-width-1-2@s footer-wrapper" data-uk-grid>
            <div className="footer-left">© SEO Developments {new Date().getFullYear()}</div>
            <div className="footer-right">Built by <a href="https://www.phaeria.com">phaeria</a></div>
          </div>
        </footer>
    </div>
)

export default Footer